import axios from 'axios';
import { Message } from 'element-ui';
import { USER_INFO } from '@/utils/const';
import { logout } from '@/utils/app';

// eslint-disable-next-line
let instance;

const setUserInfo = (config) => {
  let userInfo = {};
  try {
    const storageInfo = localStorage.getItem(USER_INFO) || '{}';
    userInfo = JSON.parse(storageInfo);
  } catch (e) {
    console.error(e);
  }
  if (userInfo.id) {
    // eslint-disable-next-line
    config.headers['user-id'] = userInfo.id;
  }
};

if (!instance) {
  instance = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? '' : 'api',
    timeout: 5000,
    headers: {
    },
  });

  instance.interceptors.request.use(
    (config) => {
      // 设置requestHeader
      setUserInfo(config);

      // console.log(config);
      return config;
    },
    (error) => Promise.reject(error),
  );

  instance.interceptors.response.use(
    (response) => {
      // console.log(response);
      if (response.data.code === 5007) {
        logout();
      }

      return response.data;
    },
    (error) => {
      // console.log(error);
      Message.error(error.message || error.msg || '网络开小差了');
      return Promise.reject(error);
    },
  );
}

export default instance;
