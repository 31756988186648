export const USER_INFO = '用户信息';
export const PEMISSION = '权限';

export const getStorage = (key, defaultStr) => {
  const storage = localStorage.getItem(key) || defaultStr;
  try {
    return JSON.parse(storage);
  } catch (e) {
    return {};
  }
};

export const getUserInfo = () => getStorage(USER_INFO, '{}');
export const getPemission = () => {
  const array = getStorage(PEMISSION, '[]');

  const pemissionObj = {};

  array.forEach((element) => {
    pemissionObj[element] = 1;
  });
  // 共有权限
  pemissionObj.all = 1;

  return pemissionObj;
};

export const classList = [
  { label: '大一', value: '大一' },
  { label: '大二', value: '大二' },
  { label: '大三', value: '大三' },
  { label: '大四', value: '大四' },
  { label: '研一', value: '研一' },
  { label: '研二', value: '研二' },
  { label: '研三', value: '研三' },
  { label: '博士', value: '博士' },
  { label: '其他', value: '其他' },
];

export const teacherList = [
  { label: '教职工', value: '教职工' },
  { label: '教工亲属', value: '教工亲属' },
  { label: '社区人员', value: '社区人员' },
];

export const bindStatus = [
  { label: '已绑定', value: 2 },
  { label: '未绑定', value: 1 },
  { label: '已过期', value: -1 },
  // { label: '已注销', value: 3 }, // TODO: 不知道具体value先写一个
];

export const BIND_STATUS_TEXT = {
  '-1': '已过期',
  1: '未绑定',
  2: '已绑定',
  3: '已注销',
};

export const generateTypes = [
  { label: '教工', value: 1, text: 'j' },
  { label: '本科生', value: 2, text: 'x' },
  { label: '研究生', value: 3, text: 'y' },
  { label: '务工人员', value: 4, text: 'w' },
];

export const logTypes = [
  { label: '小程序操作', value: 1 },
  { label: '系统后台操作', value: 2 },
];

export const LOG_TYPE_TEXT = {
  1: '小程序操作',
  2: '系统后台操作',
};

export const pemissionTree = [
  {
    id: '1',
    label: '电动车管理系统',
    children: [
      {
        id: '1.1',
        label: '车辆列表',
        children: [
          {
            id: '1.1.1',
            label: '新增',
          },
          {
            id: '1.1.2',
            label: '编辑',
          },
          {
            id: '1.1.3',
            label: '导入',
          },
          {
            id: '1.1.4',
            label: '删除',
          },
          {
            id: '1.1.5',
            label: '导出',
          },
          {
            id: '1.1.6',
            label: '解绑',
          },
        ],
      },
      {
        id: '1.2',
        label: '车牌管理',
        children: [
          {
            id: '1.2.1',
            label: '新增',
          },
          {
            id: '1.2.2',
            label: '删除',
          },
        ],
      },
    ],
  },
  {
    id: '2',
    label: '权限管理',
    children: [
      {
        id: '2.1',
        label: '用户列表',
        children: [
          {
            id: '2.1.1',
            label: '新增',
          },
          {
            id: '2.1.2',
            label: '禁用',
          },
          {
            id: '2.1.3',
            label: '删除',
          },
        ],
      },
      {
        id: '2.2',
        label: '角色列表',
        children: [
          {
            id: '2.2.1',
            label: '新增',
          },
          {
            id: '2.2.2',
            label: '删除',
          },
        ],
      },
    ],
  },
  {
    id: '3',
    label: '日志管理',
    children: [
      {
        id: '3.1',
        label: '日志列表',
        children: [
          {
            id: '3.1.1',
            label: '导出',
          },
        ],
      },
    ],
  },
];
