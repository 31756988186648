<template>
  <div class="el-row">
    <div class="el-row">
      <h5 class="menu-title">车牌管理系统</h5>
    </div>
    <el-menu class="el-menu-vertical-demo">
      <!-- 车辆管理 -->
      <el-submenu index="1" v-if="pemission['1']">
        <template slot="title">
          <i class="el-icon-menu"></i>
          <span>电动车信息管理系统</span>
        </template>
        <el-menu-item
          index="1-1"
          v-if="pemission['1.1']"
          @click="handleClick('car-list')"
        >
          <i class="el-icon-bicycle"></i>
          车辆列表
        </el-menu-item>
        <el-menu-item
          index="1-2"
          v-if="pemission['1.2']"
          @click="handleClick('license-list')"
        >
          <i class="el-icon-postcard"></i>
          车牌管理
        </el-menu-item>
      </el-submenu>

      <el-submenu index="2" v-if="pemission['2']">
        <template slot="title">
          <i class="el-icon-s-custom"></i>
          <span>权限管理</span>
        </template>
        <el-menu-item
          index="2-1"
          v-if="pemission['2.1']"
          @click="handleClick('user-list')"
        >
          <i class="el-icon-user"></i>
          用户列表
        </el-menu-item>
        <el-menu-item
          index="2-2"
          v-if="pemission['2.2']"
          @click="handleClick('role-list')"
        >
          <i class="el-icon-key"></i>
          角色列表
        </el-menu-item>
      </el-submenu>

      <el-submenu index="3" v-if="pemission['3']">
        <template slot="title">
          <i class="el-icon-chat-dot-square"></i>
          <span>日志管理</span>
        </template>
        <el-menu-item
          index="3-1"
          v-if="pemission['3.1']"
          @click="handleClick('log-list')"
        >
          <i class="el-icon-files"></i>
          日志列表
        </el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
import { getPemission } from '@/utils/const';

export default {
  data() {
    return {
      pemission: getPemission(),
    };
  },
  methods: {
    handleClick(name) {
      this.$router.push({
        name,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-menu-vertical-demo {
  height: 100%;
}
.menu-title {
  text-align: center;
  font-size: 20px;
  background: #0096ff;
  margin: 0;
  padding: 20px;
  color: white;
}
</style>
