import Vue from 'vue';
import VueRouter from 'vue-router';
import { getPemission } from '@/utils/const';
import Home from '../views/Home.vue';

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    // meta: { pemissionId: 'all' },
    children: [
      {
        path: '',
        redirect: 'default',
      },
      {
        path: 'car-list',
        name: 'car-list',
        meta: { title: '车辆列表', pemissionId: '1.1' },
        component: () => import('../views/CarList.vue'),
      },
      {
        path: 'license-list',
        name: 'license-list',
        meta: { title: '车牌管理', pemissionId: '1.2' },
        component: () => import('../views/LicenseList.vue'),
      },
      {
        path: 'user-list',
        name: 'user-list',
        meta: { title: '用户列表', pemissionId: '2.1' },
        component: () => import('../views/UserList.vue'),
      },
      {
        path: 'role-list',
        name: 'role-list',
        meta: { title: '角色列表', pemissionId: '2.2' },
        component: () => import('../views/RoleList.vue'),
      },
      {
        path: 'log-list',
        name: 'log-list',
        meta: { title: '日志列表', pemissionId: '3.1' },
        component: () => import('../views/LogList.vue'),
      },
      // ...其他子路由
      {
        path: '/403',
        name: '403',
        meta: { title: '登录', pemissionId: 'all' },
        component: () => import('../views/403.vue'),
      },
      {
        path: '/default',
        name: 'default',
        meta: { title: '', pemissionId: 'all' },
        component: () => import('../views/default.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: { title: '登录', pemissionId: 'all' },
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
  },
];

const router = new VueRouter({
  routes,
  // mode: 'history',
  // base: 'cms',
});

Vue.mixin({
  beforeRouteEnter(to, from, next) {
    const pemissionObj = getPemission();
    pemissionObj.all = 'all';

    if (pemissionObj[to.meta.pemissionId]) {
      document.title = to.meta.title || '车牌管理系统';
      next();
    } else {
      next({ path: '/403' });
    }
  },
});

export default router;
