<template>
  <div class="home">
    <Menu class="left-menu"/>
    <div class="right-content">
      <!-- 顶部nav -->
      <div class="nav-bar">

        <div class="client-name">
          {{ userInfo.name || userInfo.role_name || '角色名字' }}
        </div>
        <!-- 最右侧dropdown -->
        <el-dropdown class="dropdown" @command="handleCommand">
          <div class="avatar"></div>
          <i class="el-icon-arrow-down" style="font-size: 12px;margin-left: 10px;"></i>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-plus" command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <!-- 底部 content -->
      <div class="router-wrapper">
        <el-card class="card">
          <router-view/>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from '@/components/Menu.vue';
import { getUserInfo } from '@/utils/const';
import { logout } from '@/utils/app';

export default {
  name: 'Home',
  components: {
    Menu,
  },
  data() {
    return {
      userInfo: getUserInfo(),
    };
  },
  methods: {
    logout,

    handleCommand(command) {
      if (command === 'logout') {
        this.logout();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  display: flex;
}
.left-menu {
  min-width: 240px;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

.right-content {
  flex-grow: 1;
  background-color: #f5f7f9;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.nav-bar {
  width: 100%;
  height: 40px;
  background-color:white;
  display: flex;
  align-content: center;
  justify-content: flex-end;
  padding-right: 20px;
  line-height: 40px;

  .avatar {
    display: inline-block;
    text-align: center;
    background: #ccc;
    color: #fff;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
    vertical-align: middle;
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 16px;
  }
}

.client-name {
  color: #000;
  padding-right: 30px;
  font-size: 12px;
}

.router-wrapper {
  width: 100%;
  height: 100%;
  padding: 18px;
  overflow: hidden;
}

.card {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
</style>
