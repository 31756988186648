import request from '@/utils/request';
// import VueRouter from '@/router';

// eslint-disable-next-line
export const logout = () => {
  const toHome = () => {
    localStorage.clear();
    window.location.href = `${window.location.origin}/#/login`;
  };

  request({
    method: 'post',
    url: '/manager/logout',
  })
    .then(toHome)
    .catch(toHome)
    .then(toHome);
};
